<template>
  <main class="flex-1 overflow-x-hidden overflow-y-auto px-2">
    <div class="container mx-auto">
      <h1
        class="flex items-center font-sans font-bold break-normal text-indigo-500  text-xl md:text-2xl py-2"
      >
        Inventory Changes
      </h1>

      <div class="mb-4">
        <!-- component -->
        <div id="recipients" class="">
          <table
            id="inventory-change-tickets"
            class="border-collapse w-full text-sm"
          >
            <thead>
              <tr>
                <th
                  data-priority="1"
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell"
                >
                  Event
                </th>
                <th
                  data-priority="1"
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell"
                >
                  City
                </th>
                <th
                  data-priority="2"
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Dates
                </th>
                <th
                  data-priority="2"
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Today AvlTixs
                </th>
                <th
                  data-priority="3"
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell"
                >
                  Today
                </th>
                <th
                  data-priority="4"
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell"
                >
                  3Day
                </th>
                <th
                  data-priority="5"
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell"
                >
                  7Day
                </th>
                <th
                  data-priority="6"
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell"
                >
                  14Day
                </th>
                <th
                  data-priority="7"
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell"
                >
                  21DAY
                </th>
                <th
                  data-priority="8"
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell"
                >
                  1MON
                </th>
                <th
                  data-priority="9"
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell"
                >
                  2MON
                </th>
                <th
                  data-priority="10"
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell"
                >
                  3MON
                </th>
              </tr>
            </thead>
            <tbody id="ticketsList">
              <tr
                v-if="!data.length"
                class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
              >
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                  colspan="12"
                >
                  {{ loading }}
                </td>
              </tr>
              <tr
                v-for="tr in data"
                :key="tr.id"
                class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
                id="ic-tix-data-wait"
              >
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                >
                  {{ tr.event }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                >
                  {{ tr.city }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                >
                  {{ tr.dates }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                >
                  {{ tr.availabletixs }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                >
                  {{ tr.availabletixs - tr.TwoDay }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                >
                  {{ tr.availabletixs - tr.ThreeDay }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                >
                  {{ tr.availabletixs - tr.SevenDay }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                >
                  {{ tr.availabletixs - tr.FourteenDay }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                >
                  {{ tr.availabletixs - tr.TwentyOneDay }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                >
                  {{ tr.availabletixs - tr.OneMonth }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                >
                  {{ tr.availabletixs - tr.TwoMonth }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                >
                  {{ tr.availabletixs - tr.ThreeMonth }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "InventoryChanges",
  data() {
    return {
      loading: "Please wait...",
      data: []
    };
  },
  created() {
    this.get();
  },
  methods: {
    get() {
      this.$axios
        .get(`${this.$api}/ticket/inventory-change`)
        .then(res => {
          this.data = res.data.data;
          if (this.data.length === 0) {
            this.loading = "No data found";
          }
          if (!res.data.success) {
            this.loading = "No data found";
          }
          var self = this;
          var existIcTix = setInterval(function() {
            if (self.jQuery("#ic-tix-data-wait").length) {
              clearInterval(existIcTix);
              self.jQuery("#inventory-change-tickets").DataTable({
                autoWidth: false,
                // aaSorting: [[3, "desc"]],
                filter: false,
                ordering: false,
                responsive: true,
                aLengthMenu: [100],
                processing: true,
                language: {
                  processing:
                    "<i class='fa fa-refresh fa-spin' style='font-size:4em;margin-top:20%;'></i>"
                },
                serverSide: true,
                ajax: {
                  url: `${self.$api}/ticket/inventory-change`,
                  dataFilter: function(data) {
                    const records = JSON.parse(data);
                    const json = records.data;
                    console.log({ json });
                    return JSON.stringify({
                      recordsTotal: records.total,
                      recordsFiltered: records.total,
                      data: json
                    }); // return JSON string
                  }
                },
                deferLoading: res.data.total,
                columns: [
                  {
                    data: "event",
                    title: "Event",
                    render: function(data, _type, row) {
                      console.log({ row });
                      return `<a
                        class="cursor-pointer text-blue-600"
                        href="/admin/ticket-chart/${data}/${row.city}"
                        target="_blank"
                        >${data}</a
                      >`;
                    }
                  },
                  { data: "city" },
                  { data: "dates" },
                  { data: "availabletixs" },
                  {
                    data: "TwoDay",
                    title: "Today",
                    render: function(data, _type, row) {
                      return row.availabletixs - data;
                    }
                  },
                  {
                    data: "ThreeDay",
                    title: "3Day",
                    render: function(data, _type, row) {
                      return row.availabletixs - data;
                    }
                  },
                  {
                    data: "SevenDay",
                    title: "7Day",
                    render: function(data, _type, row) {
                      return row.availabletixs - data;
                    }
                  },
                  {
                    data: "FourteenDay",
                    title: "14Day",
                    render: function(data, _type, row) {
                      return row.availabletixs - data;
                    }
                  },
                  {
                    data: "TwentyOneDay",
                    title: "21DAY",
                    render: function(data, _type, row) {
                      return row.availabletixs - data;
                    }
                  },
                  {
                    data: "OneMonth",
                    title: "1MON",
                    render: function(data, _type, row) {
                      return row.availabletixs - data;
                    }
                  },
                  {
                    data: "TwoMonth",
                    title: "2MON",
                    render: function(data, _type, row) {
                      return row.availabletixs - data;
                    }
                  },
                  {
                    data: "ThreeMonth",
                    title: "3MON",
                    render: function(data, _type, row) {
                      return row.availabletixs - data;
                    }
                  }
                ]
              });
            }
          }, 100);
        })
        .catch(err => {
          console.log(err);
          this.loading = "Something went wrong, please try again";
        });
    }
  }
};
</script>
